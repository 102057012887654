.animation-infinite {
    animation-iteration-count: infinite !important;
    -webkit-animation-iteration-count: infinite !important;
}
$maxDelay: 25*3;
@mixin animation-delay-x {
    @for $i from 0 through $maxDelay - 1 {
        .animation-delay-#{$i} {
            animation-delay: #{$i/25}s !important;
            -webkit-animation-delay: #{$i/25}s !important;
        }
    }
}
@include animation-delay-x;

.jello-horizontal {
    -webkit-animation: jello-horizontal 5s both;
    animation: jello-horizontal 5s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-4 22:16:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(0.95, 1.05, 1);
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, 0.95, 1);
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.slide-out-blurred-right {
    -webkit-animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: slide-out-blurred-right 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-4 22:20:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-right {
    0% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
        transform: translateX(1000px) scaleX(2) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}

@keyframes slide-out-blurred-right {
    0% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
        transform: translateX(1000px) scaleX(2) scaleY(0.2);
        -webkit-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }
}


.slide-in-blurred-left {
    -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-4 22:29:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.slide-in-blurred-right {
    -webkit-animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: slide-in-blurred-right 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-4 22:29:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes slide-in-blurred-right {
    0% {
        -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        -webkit-filter: blur(40px);
        filter: blur(40px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) scaleY(1) scaleX(1);
        transform: translateX(0) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}


.bounce-in-top {
    -webkit-animation: bounce-in-top 1.1s both;
    animation: bounce-in-top 1.1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-4 22:59:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-top {
    0% {
        -webkit-transform: translateY(-500px);
        transform: translateY(-500px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-65px);
        transform: translateY(-65px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-28px);
        transform: translateY(-28px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.bounce-in-top-small {
    -webkit-animation: bounce-in-top-small 1.1s both;
    animation: bounce-in-top-small 1.1s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-4 22:59:54
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-top-small
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top-small {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-6.5px);
        transform: translateY(-6.5px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-2.8px);
        transform: translateY(-2.8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-0.8px);
        transform: translateY(-0.8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes bounce-in-top-small {
    0% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
        opacity: 0;
    }

    38% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
        opacity: 1;
    }

    55% {
        -webkit-transform: translateY(-6.5px);
        transform: translateY(-6.5px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    72% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    81% {
        -webkit-transform: translateY(-2.8px);
        transform: translateY(-2.8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    90% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    95% {
        -webkit-transform: translateY(-0.8px);
        transform: translateY(-0.8px);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}


.slit-in-vertical {
    -webkit-animation: slit-in-vertical 1s ease-in-out both;
    animation: slit-in-vertical 1s ease-in-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-5 15:44:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slit-in-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }

    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

@keyframes slit-in-vertical {
    0% {
        -webkit-transform: translateZ(-800px) rotateY(90deg);
        transform: translateZ(-800px) rotateY(90deg);
        opacity: 0;
    }

    54% {
        -webkit-transform: translateZ(-160px) rotateY(87deg);
        transform: translateZ(-160px) rotateY(87deg);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(0) rotateY(0);
        transform: translateZ(0) rotateY(0);
    }
}

.slide-out-elliptic-left-bck {
    -webkit-animation: slide-out-elliptic-left-bck 0.7s ease-in both;
    animation: slide-out-elliptic-left-bck 0.7s ease-in both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-5 15:45:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-elliptic-left-bck 
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-left-bck {
    0% {
        -webkit-transform: translateX(0) rotateY(0) scale(1);
        transform: translateX(0) rotateY(0) scale(1);
        -webkit-transform-origin: 2000px 50%;
        transform-origin: 2000px 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px) rotateY(30deg) scale(0);
        transform: translateX(-1000px) rotateY(30deg) scale(0);
        -webkit-transform-origin: -100% 50%;
        transform-origin: -100% 50%;
        opacity: 1;
    }
}

@keyframes slide-out-elliptic-left-bck {
    0% {
        -webkit-transform: translateX(0) rotateY(0) scale(1);
        transform: translateX(0) rotateY(0) scale(1);
        -webkit-transform-origin: 2000px 50%;
        transform-origin: 2000px 50%;
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(-1000px) rotateY(30deg) scale(0);
        transform: translateX(-1000px) rotateY(30deg) scale(0);
        -webkit-transform-origin: -100% 50%;
        transform-origin: -100% 50%;
        opacity: 1;
    }
}

.roll-out-blurred-right {
    -webkit-animation: roll-out-blurred-right 0.65s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: roll-out-blurred-right 0.65s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-5 15:47:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-blurred-right {
    0% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px) rotate(720deg);
        transform: translateX(1000px) rotate(720deg);
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }
}

@keyframes roll-out-blurred-right {
    0% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(1000px) rotate(720deg);
        transform: translateX(1000px) rotate(720deg);
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }
}

.roll-in-blurred-left {
    -webkit-animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    animation: roll-in-blurred-left 0.65s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-5 16:28:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) rotate(-720deg);
        transform: translateX(-1000px) rotate(-720deg);
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

@keyframes roll-in-blurred-left {
    0% {
        -webkit-transform: translateX(-1000px) rotate(-720deg);
        transform: translateX(-1000px) rotate(-720deg);
        -webkit-filter: blur(50px);
        filter: blur(50px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
}

.roll-in-left {
    -webkit-animation: roll-in-left 0.6s ease-out both;
    animation: roll-in-left 0.6s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-5 16:49:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-left {
    0% {
        -webkit-transform: translateX(-1000px) rotate(-540deg);
        transform: translateX(-1000px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-left {
    0% {
        -webkit-transform: translateX(-1000px) rotate(-540deg);
        transform: translateX(-1000px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

.roll-in-right {
    -webkit-animation: roll-in-right 0.6s ease-out both;
    animation: roll-in-right 0.6s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-5 16:49:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-right {
    0% {
        -webkit-transform: translateX(1000px) rotate(-540deg);
        transform: translateX(1000px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

@keyframes roll-in-right {
    0% {
        -webkit-transform: translateX(1000px) rotate(-540deg);
        transform: translateX(1000px) rotate(-540deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg);
        opacity: 1;
    }
}

.roll-in-left-effect {
    -webkit-animation: roll-in-left-effect 1s ease-out both;
    animation: roll-in-left-effect 1s ease-out both;
}

@-webkit-keyframes roll-in-left-effect {
    0% {
        -webkit-transform: translateX(-100px) rotate(-540deg);
        transform: translateX(-100px) rotate(-540deg);
        opacity: 0;
    }

    50% {
        -webkit-transform: translateX(0) rotate(0deg) scale(0.4);
        transform: translateX(0) rotate(0deg) scale(0.4);
        opacity: 1;
    }

    75% {
        -webkit-transform: translateX(0) rotate(360deg) scale(1.4);
        transform: translateX(0) rotate(360deg) scale(1.4);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg) scale(1);
        opacity: 1;
    }
}

@keyframes roll-in-left-effect {
    0% {
        -webkit-transform: translateX(-100px) rotate(-540deg);
        transform: translateX(-100px) rotate(-540deg);
        opacity: 0;
    }

    50% {
        -webkit-transform: translateX(0) rotate(0deg) scale(0.4);
        transform: translateX(0) rotate(0deg) scale(0.4);
        opacity: 1;
    }

    75% {
        -webkit-transform: translateX(0) rotate(360deg) scale(1.4);
        transform: translateX(0) rotate(360deg) scale(1.4);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateX(0) rotate(0deg);
        transform: translateX(0) rotate(0deg) scale(1);
        opacity: 1;
    }
}

.puff-in-center {
    -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-6 15:20:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-in-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

@keyframes puff-in-center {
    0% {
        -webkit-transform: scale(2);
        transform: scale(2);
        -webkit-filter: blur(4px);
        filter: blur(4px);
        opacity: 0;
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-filter: blur(0px);
        filter: blur(0px);
        opacity: 1;
    }
}

.swirl-in-fwd {
    -webkit-animation: swirl-in-fwd 0.6s ease-out both;
    animation: swirl-in-fwd 0.6s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-6 15:22:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

@keyframes swirl-in-fwd {
    0% {
        -webkit-transform: rotate(-540deg) scale(0);
        transform: rotate(-540deg) scale(0);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotate(0) scale(1);
        transform: rotate(0) scale(1);
        opacity: 1;
    }
}

.heartbeat {
    -webkit-animation: heartbeat 1.5s ease-in-out infinite alternate both;
    animation: heartbeat 1.5s ease-in-out infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-12 18:41:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }

    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }

    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.tracking-out-contract-bck {
    -webkit-animation: tracking-out-contract-bck 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: tracking-out-contract-bck 4s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 21:29:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-out-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract-bck {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-500px);
        transform: translateZ(-500px);
        opacity: 0;
    }
}

@keyframes tracking-out-contract-bck {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    100% {
        letter-spacing: -0.5em;
        -webkit-transform: translateZ(-500px);
        transform: translateZ(-500px);
        opacity: 0;
    }
}

.fade-out {
    -webkit-animation: fade-out 5s ease-out both;
    animation: fade-out 5s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 21:35:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.slide-out-blurred-bottom {
    -webkit-animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    animation: slide-out-blurred-bottom 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 23:48:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-bottom {
    0% {
        -webkit-transform: translateY(0px) scaleY(1.5) scaleX(1.5);
        transform: translateY(0px) scaleY(1.5) scaleX(1.5);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(100px) scaleY(2) scaleX(0.2);
        transform: translateY(100px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(10px);
        filter: blur(10px);
        opacity: 0;
    }
}

@keyframes slide-out-blurred-bottom {
    0% {
        -webkit-transform: translateY(0px) scaleY(1.5) scaleX(1.5);
        transform: translateY(0px) scaleY(1.5) scaleX(1.5);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateY(100px) scaleY(2) scaleX(0.2);
        transform: translateY(100px) scaleY(2) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        -webkit-filter: blur(10px);
        filter: blur(10px);
        opacity: 0;
    }
}

.swing-in-top-fwd {
    -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-2-2 15:48:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-fwd {
    0% {
        -webkit-transform: rotateX(-100deg);
        transform: rotateX(-100deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }
}

.swing-out-top-fwd {
    -webkit-animation: swing-out-top-fwd 0.55s cubic-bezier(0.600, -0.280, 0.735, 0.045) infinite both;
    animation: swing-out-top-fwd 0.55s cubic-bezier(0.600, -0.280, 0.735, 0.045) infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-2-2 21:51:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-out-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-fwd {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
}

@keyframes swing-out-top-fwd {
    0% {
        -webkit-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        -webkit-transform-origin: top;
        transform-origin: top;
        opacity: 0;
    }
}

.slide-out-bck-center {
    -webkit-animation: slide-out-bck-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bck-center 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-2-2 21:53:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px);
        transform: translateZ(-1100px);
        opacity: 0;
    }
}

@keyframes slide-out-bck-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: translateZ(-1100px);
        transform: translateZ(-1100px);
        opacity: 0;
    }
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-3-27 9:57:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
.slide-in-elliptic-top-fwd {
    -webkit-animation: slide-in-elliptic-top-fwd 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-elliptic-top-fwd 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-4-9 13:58:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-top-fwd {
    0% {
        -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
        transform: translateY(-600px) rotateX(-30deg) scale(0);
        -webkit-transform-origin: 50% 100%;
        transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) rotateX(0) scale(1);
        transform: translateY(0) rotateX(0) scale(1);
        -webkit-transform-origin: 50% 1400px;
        transform-origin: 50% 1400px;
        opacity: 1;
    }
}
.slide-in-bottom {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-4-13 16:31:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-in-bottom {
    0% {
        -webkit-transform: translateY(1000px);
        transform: translateY(1000px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.text-pop-up-top {
    -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 11:50:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-50px);
        transform: translateY(-50px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}
.text-pop-up-top-small {
    -webkit-animation: text-pop-up-top-small 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: text-pop-up-top-small 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 11:50:6
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top-small
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-top-small {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}

@keyframes text-pop-up-top-small {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: none;
    }

    100% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        text-shadow: 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
    }
}
.shadow-drop-2-center {
    -webkit-animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 11:55:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

@keyframes shadow-drop-2-center {
    0% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }

    100% {
        -webkit-transform: translateZ(50px);
        transform: translateZ(50px);
        -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
}

.flip-scale-up-hor {
    -webkit-animation: flip-scale-up-hor 0.5s linear both;
    animation: flip-scale-up-hor 0.5s linear both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 12:4:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-scale-up-hor
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-up-hor {
    0% {
        -webkit-transform: scale(1) rotateX(0);
        transform: scale(1) rotateX(0);
    }

    50% {
        -webkit-transform: scale(1.5) rotateX(-90deg);
        transform: scale(1.5) rotateX(-90deg);
    }

    75% {
        -webkit-transform: scale(1) rotateX(-180deg);
        transform: scale(1) rotateX(-180deg);
    }

    100% {
        -webkit-transform: scale(1) rotateX(0);
        transform: scale(1) rotateX(0);
    }
}

@keyframes flip-scale-up-hor {
    0% {
        -webkit-transform: scale(1) rotateX(0);
        transform: scale(1) rotateX(0);
    }

    50% {
        -webkit-transform: scale(1.5) rotateX(-90deg);
        transform: scale(1.5) rotateX(-90deg);
    }

    75% {
        -webkit-transform: scale(1) rotateX(-180deg);
        transform: scale(1) rotateX(-180deg);
    }

    100% {
        -webkit-transform: scale(1) rotateX(0);
        transform: scale(1) rotateX(0);
    }
}
.rotate-out-2-cw {
    -webkit-animation: rotate-out-2-cw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: rotate-out-2-cw 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 12:8:53
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-out-2-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
}

@keyframes rotate-out-2-cw {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        opacity: 0;
    }
}
.flip-out-hor-top {
    -webkit-animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: flip-out-hor-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 12:9:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}

@keyframes flip-out-hor-top {
    0% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: rotateX(70deg);
        transform: rotateX(70deg);
        opacity: 0;
    }
}


.raise-top {
    -webkit-animation: raise-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: raise-top 0.45s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-30 12:9:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation raise-top 
 * ----------------------------------------
 */
@-webkit-keyframes raise-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}

@keyframes raise-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-100px);
        transform: translateY(-100px);
    }
}

.kenburns-right {
    -webkit-animation: kenburns-right 5s ease-out both;
    animation: kenburns-right 5s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 23:52:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-right {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 84% 50%;
        transform-origin: 84% 50%;
    }

    100% {
        -webkit-transform: scale(1.25) translateX(20px);
        transform: scale(1.25) translateX(20px);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}

@keyframes kenburns-right {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 84% 50%;
        transform-origin: 84% 50%;
    }

    100% {
        -webkit-transform: scale(1.25) translateX(20px);
        transform: scale(1.25) translateX(20px);
        -webkit-transform-origin: right;
        transform-origin: right;
    }
}
.kenburns-bottom-left {
    -webkit-animation: kenburns-bottom-left 5s ease-out both;
    animation: kenburns-bottom-left 5s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-1-5 10:29:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-bottom-left
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom-left {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 16% 84%;
        transform-origin: 16% 84%;
    }

    100% {
        -webkit-transform: scale(1.25) translate(-20px, 15px);
        transform: scale(1.25) translate(-20px, 15px);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
    }
}

@keyframes kenburns-bottom-left {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 16% 84%;
        transform-origin: 16% 84%;
    }

    100% {
        -webkit-transform: scale(1.25) translate(-20px, 15px);
        transform: scale(1.25) translate(-20px, 15px);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom;
    }
}
.kenburns-top-right {
    -webkit-animation: kenburns-top-right 5s ease-out both;
    animation: kenburns-top-right 5s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-1-5 10:30:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top-right {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 84% 16%;
        transform-origin: 84% 16%;
    }

    100% {
        -webkit-transform: scale(1.25) translate(20px, -15px);
        transform: scale(1.25) translate(20px, -15px);
        -webkit-transform-origin: right top;
        transform-origin: right top;
    }
}

@keyframes kenburns-top-right {
    0% {
        -webkit-transform: scale(1) translate(0, 0);
        transform: scale(1) translate(0, 0);
        -webkit-transform-origin: 84% 16%;
        transform-origin: 84% 16%;
    }

    100% {
        -webkit-transform: scale(1.25) translate(20px, -15px);
        transform: scale(1.25) translate(20px, -15px);
        -webkit-transform-origin: right top;
        transform-origin: right top;
    }
}
.kenburns-bottom {
    -webkit-animation: kenburns-bottom 5s ease-out both;
    animation: kenburns-bottom 5s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-1-5 12:52:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-bottom
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 84%;
        transform-origin: 50% 84%;
    }

    100% {
        -webkit-transform: scale(1.25) translateY(15px);
        transform: scale(1.25) translateY(15px);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
    }
}

@keyframes kenburns-bottom {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 84%;
        transform-origin: 50% 84%;
    }

    100% {
        -webkit-transform: scale(1.25) translateY(15px);
        transform: scale(1.25) translateY(15px);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
    }
}
