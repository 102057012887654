$grad-1: #262a30;
$grad-2: #f3dcbe;

.bg-gradient-00 {
    background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}
.bg-gradient-01 {
    background: linear-gradient(135deg, #f093fb 0%, #f5576c 100%);
}
.bg-gradient-02 {
    background: linear-gradient(135deg, #5ee7df 0%, #b490ca 100%);
}
.bg-gradient-03 {
    background: linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%);
}
.bg-gradient-04 {
    background: linear-gradient(135deg, #ffafbd 0%, #ffc3a0 100%);
}
.bg-gradient-05 {
    background: linear-gradient(135deg, #2193b0 0%, #6dd5ed 100%);
}
.bg-gradient-06 {
    background: linear-gradient(135deg, #cc2b5e 0%, #753a88 100%);
}
.bg-gradient-07 {
    background: linear-gradient(135deg, #56ab2f 0%, #a8e063 100%);
}
.bg-gradient-08 {
    background: linear-gradient(135deg, #eacda3 0%, #d6ae7b 100%);
}
.bg-gradient-09 {
    background: linear-gradient(135deg, #ED242C 0%, #D2218B 100%);
}
.bg-gradient-10 {
    background: linear-gradient(135deg, #d6ae7b 0%, gold 100%);
}

.bg-gradient-bee-diagonal {
    background: linear-gradient(60.95deg, $grad-1 0%, $grad-2 100%) !important;
}
.bg-gradient-bee-vertical {
    background: linear-gradient(180deg, $grad-1 0%, #ffffff 100%);
}
.bg-gradient-bee-vertical-shadow {
    background: linear-gradient(180deg, #555 0%, rgba(255,0,0,0) 100%);
}
.bg-gradient-bee-vertical-shadow-inverse {
    background: linear-gradient(180deg, #ffffff 0%, $grad-1 100%);
}
.bg-gradient-bee2-diagonal {
    background: linear-gradient(60.95deg, #8924ed 0%, #ed24a0 100%) !important;
}
.bg-gradient-bee2-vertical {
    background: linear-gradient(180deg, $grad-1 0%, $grad-2 100%);
}
.bg-gradient-gold {
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
                radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, gold 25%, gold 62.5%, #5d4a1f 100%);
}